<template>
  <AuthWrapper>
    <div class="auth-contents" v-if="mailAcc">
      <div class="auth-container">
        <sdHeading as="h2" class="sign-in__header"
          >{{ i18n.t('authPage.emailAuth') }}
          <a-select
            class="sign-in__language"
            v-model:value="currentLanguage"
            @change="changeLanguage"
            :options="languages"
            ref="select"
          />
        </sdHeading>
        <div v-if="!mailAcc.errors">
          <p>
            {{ i18n.t('authPage.congratulations') }}
          </p>
          <p>
            {{ i18n.t('authPage.accountIsActive') }}
          </p>
          <p class="forgot-text">
            {{ i18n.t('authPage.emailAuthInfo') }}
          </p>
        </div>
        <p v-else>
          {{ i18n.t('authPage.token', mailAcc.errors.confirmation_token ? 2 : 1) }}
          {{
            mailAcc.errors.email
              ? mailAcc.errors.email[0]
              : mailAcc.errors.confirmation_token
              ? mailAcc.errors.confirmation_token[0]
              : mailAcc.errors.email ?? mailAcc.errors.confirmation_token ?? 'Ошибка'
          }}
        </p>
        <div class="button__wrapper">
          <router-link to="/">
            <sdButton type="primary" size="default" style="margin-top: 20px">
              {{ i18n.t('authPage.signIn') }}
            </sdButton>
          </router-link>
        </div>
      </div>
    </div>
  </AuthWrapper>
</template>
<script>
import { onMounted, computed, ref } from 'vue';
import { AuthWrapper } from './style';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { languages } from '@/config/helpers/JSONdata.js';
import { getCookie, setCookie } from '@/utility/localStorageControl';

const ForgotPassword = {
  name: 'ForgotPassword',
  components: { AuthWrapper },
  setup() {
    const route = useRoute();
    const { state, dispatch } = useStore();
    const i18n = useI18n();
    const currentLanguage = ref(getCookie('language'));

    const confirmCode = route.params.confirmationCode;
    const loggedIn = computed(() => state.auth.loggedIn);
    const mailAcc = computed(() => state.profile.mailAcc);

    const changeLanguage = (data) => {
      currentLanguage.value = data;
      i18n.locale.value = data;
      setCookie({ name: 'language', value: data });
    };

    onMounted(() => {
      if (getCookie('language')) {
        i18n.locale.value = getCookie('language');
      }
      dispatch('mailAccept', confirmCode);
    });
    return {
      confirmCode,
      mailAcc,
      i18n,
      languages,
      currentLanguage,
      loggedIn,
      changeLanguage,
    };
  },
};

export default ForgotPassword;
</script>

<style scoped lang="scss">
@media screen and (max-width: 500px) {
  .auth-contents {
    text-align: left;

    .button__wrapper {
      text-align: center !important;
    }
  }
}
</style>
